//
// Variables
//

// Reboot
$app-bg-color: #f5f8fa !default;
$app-bg-color-dark: #151521 !default;
$app-blank-bg-color: $white;
$app-blank-bg-color-dark: $app-bg-color-dark;

// General
$app-general-transition-duration: 0.3s !default;
$app-general-transition-timing: ease !default;
$app-general-root-font-size-desktop: 13px !default;
$app-general-root-font-size-tablet: 12px !default;
$app-general-root-font-size-mobile: 12px !default;

// Container
$app-container-padding-x: 32px !default;
$app-container-padding-x-mobile: 1.5rem !default;

// Page
$app-page-bg-color: null !default;
$app-page-bg-color-dark: null !default;

// Main
$app-main-transition: margin $app-general-transition-duration $app-general-transition-timing;
$app-main-padding-y: null !default;
$app-main-padding-y-mobile: null !default;
$app-main-padding-x: null !default;
$app-main-padding-x-mobile: 0 !default;
$app-main-margin-y: null !default;
$app-main-margin-y-mobile: null !default;
$app-main-margin-x: null !default;
$app-main-margin-x-mobile: null !default;

// Wrapper
$app-wrapper-transition: margin-left $app-general-transition-duration $app-general-transition-timing,
  margin-right $app-general-transition-duration $app-general-transition-timing;
$app-wrapper-bg-color: null !default;
$app-wrapper-bg-color-dark: null !default;

// Content
$app-content-padding-y: 32.5px !default;
$app-content-padding-y-mobile: 1.75rem !default;
$app-content-padding-x: 0 !default;
$app-content-padding-x-mobile: 0 !default;

// Header base
$app-header-base-transition: none !default;
$app-header-base-height: 70px !default;
$app-header-base-height-mobile: 70px !default;
$app-header-base-z-index: null !default;
$app-header-base-z-index-mobile: null !default;
$app-header-base-bg-color: null !default;
$app-header-base-bg-color-dark: null !default;
$app-header-base-box-shadow: null !default;
$app-header-base-box-shadow-dark: null !default;
$app-header-base-border-bottom: null !default;
$app-header-base-border-bottom-dark: null !default;

// Header fixed
$app-header-fixed-z-index: 106 !default;
$app-header-fixed-z-index-mobile: 100 !default;

// Header minimize
$app-header-minimize-height: 70px !default;
$app-header-minimize-height-mobile: 70px !default;
$app-header-minimize-z-index: 100 !default;
$app-header-minimize-z-index-mobile: 100 !default;
$app-header-minimize-backdrop-filter: null !default;
$app-header-minimize-backdrop-filter-mobile: null !default;
$app-header-minimize-bg-color: null !default;
$app-header-minimize-bg-color-dark: null !default;
$app-header-minimize-box-shadow: null !default;
$app-header-minimize-box-shadow-dark: null !default;
$app-header-minimize-border-bottom: null !default;
$app-header-minimize-border-bottom-dark: null !default;

// Header sticky
$app-header-sticky-height: 70px !default;
$app-header-sticky-height-mobile: 70px !default;
$app-header-sticky-z-index: 100 !default;
$app-header-sticky-z-index-mobile: 100 !default;
$app-header-sticky-backdrop-filter: null !default;
$app-header-sticky-backdrop-filter-mobile: null !default;
$app-header-sticky-bg-color: null !default;
$app-header-sticky-bg-color-dark: null !default;
$app-header-sticky-box-shadow: null !default;
$app-header-sticky-box-shadow-dark: null !default;
$app-header-sticky-border-bottom: null !default;
$app-header-sticky-border-bottom-dark: null !default;

// Header primary
$app-header-primary-base-transition: none !default;
$app-header-primary-base-height: 70px !default;
$app-header-primary-base-height-mobile: 60px !default;
$app-header-primary-base-z-index: null !default;
$app-header-primary-base-z-index-mobile: null !default;
$app-header-primary-base-bg-color: null !default;
$app-header-primary-base-bg-color-dark: null !default;
$app-header-primary-base-box-shadow: null !default;
$app-header-primary-base-box-shadow-dark: null !default;
$app-header-primary-base-border-bottom: null !default;
$app-header-primary-base-border-bottom-dark: null !default;

$app-header-primary-fixed-z-index: 100 !default;

$app-header-primary-minimize-height: 70px !default;
$app-header-primary-minimize-height-mobile: 60px !default;
$app-header-primary-minimize-z-index: 100 !default;
$app-header-primary-minimize-bg-color: null !default;
$app-header-primary-minimize-bg-color-dark: null !default;
$app-header-primary-minimize-box-shadow: null !default;
$app-header-primary-minimize-box-shadow-dark: null !default;
$app-header-primary-minimize-border-top: null !default;
$app-header-primary-minimize-border-top-dark: null !default;
$app-header-primary-minimize-border-bottom: null !default;
$app-header-primary-minimize-border-bottom-dark: null !default;

$app-header-primary-sticky-height: 70px !default;
$app-header-primary-sticky-height-mobile: 60px !default;
$app-header-primary-sticky-z-index: 100 !default;
$app-header-primary-sticky-bg-color: null !default;
$app-header-primary-sticky-bg-color-dark: null !default;
$app-header-primary-sticky-box-shadow: null !default;
$app-header-primary-sticky-box-shadow-dark: null !default;
$app-header-primary-sticky-border-top: null !default;
$app-header-primary-sticky-border-top-dark: null !default;
$app-header-primary-sticky-border-bottom: null !default;
$app-header-primary-sticky-border-bottom-dark: null !default;

// Header secondary
$app-header-secondary-base-transition: none !default;
$app-header-secondary-base-height: 70px !default;
$app-header-secondary-base-height-mobile: 60px !default;
$app-header-secondary-base-z-index: null !default;
$app-header-secondary-base-z-index-mobile: null !default;
$app-header-secondary-base-bg-color: null !default;
$app-header-secondary-base-bg-color-dark: null !default;
$app-header-secondary-base-box-shadow: null !default;
$app-header-secondary-base-box-shadow-dark: null !default;
$app-header-secondary-base-border-top: null !default;
$app-header-secondary-base-border-top-dark: null !default;
$app-header-secondary-base-border-bottom: null !default;
$app-header-secondary-base-border-bottom-dark: null !default;

$app-header-secondary-fixed-z-index: 100 !default;

$app-header-secondary-minimize-height: 70px !default;
$app-header-secondary-minimize-height-mobile: 60px !default;
$app-header-secondary-minimize-z-index: 100 !default;
$app-header-secondary-minimize-bg-color: null !default;
$app-header-secondary-minimize-bg-color-dark: null !default;
$app-header-secondary-minimize-box-shadow: null !default;
$app-header-secondary-minimize-box-shadow-dark: null !default;
$app-header-secondary-minimize-border-top: null !default;
$app-header-secondary-minimize-border-top-dark: null !default;
$app-header-secondary-minimize-border-bottom: null !default;
$app-header-secondary-minimize-border-bottom-dark: null !default;

$app-header-secondary-sticky-height: 70px !default;
$app-header-secondary-sticky-height-mobile: 60px !default;
$app-header-secondary-sticky-z-index: 100 !default;
$app-header-secondary-sticky-bg-color: null !default;
$app-header-secondary-sticky-bg-color-dark: null !default;
$app-header-secondary-sticky-box-shadow: null !default;
$app-header-secondary-sticky-box-shadow-dark: null !default;
$app-header-secondary-sticky-border-top: null !default;
$app-header-secondary-sticky-border-top-dark: null !default;
$app-header-secondary-sticky-border-bottom: null !default;
$app-header-secondary-sticky-border-bottom-dark: null !default;

// Header tertiary
$app-header-tertiary-base-transition: none !default;
$app-header-tertiary-base-height: 70px !default;
$app-header-tertiary-base-height-mobile: 70px !default;
$app-header-tertiary-base-z-index: null !default;
$app-header-tertiary-base-z-index-mobile: null !default;
$app-header-tertiary-base-bg-color: null !default;
$app-header-tertiary-base-bg-color-dark: null !default;
$app-header-tertiary-base-box-shadow: null !default;
$app-header-tertiary-base-box-shadow-dark: null !default;
$app-header-tertiary-base-border-top: null !default;
$app-header-tertiary-base-border-top-dark: null !default;
$app-header-tertiary-base-border-bottom: null !default;
$app-header-tertiary-base-border-bottom-dark: null !default;

$app-header-tertiary-fixed-z-index: 100 !default;

$app-header-tertiary-minimize-height: 70px !default;
$app-header-tertiary-minimize-height-mobile: 60px !default;
$app-header-tertiary-minimize-z-index: 100 !default;
$app-header-tertiary-minimize-bg-color: null !default;
$app-header-tertiary-minimize-bg-color-dark: null !default;
$app-header-tertiary-minimize-box-shadow: null !default;
$app-header-tertiary-minimize-box-shadow-dark: null !default;
$app-header-tertiary-minimize-border-top: null !default;
$app-header-tertiary-minimize-border-top-dark: null !default;
$app-header-tertiary-minimize-border-bottom: null !default;
$app-header-tertiary-minimize-border-bottom-dark: null !default;

$app-header-tertiary-sticky-height: 70px !default;
$app-header-tertiary-sticky-height-mobile: 60px !default;
$app-header-tertiary-sticky-z-index: 100 !default;
$app-header-tertiary-sticky-bg-color: null !default;
$app-header-tertiary-sticky-bg-color-dark: null !default;
$app-header-tertiary-sticky-box-shadow: null !default;
$app-header-tertiary-sticky-box-shadow-dark: null !default;
$app-header-tertiary-sticky-border-top: null !default;
$app-header-tertiary-sticky-border-top-dark: null !default;
$app-header-tertiary-sticky-border-bottom: null !default;
$app-header-tertiary-sticky-border-bottom-dark: null !default;

// Toolbar base
$app-toolbar-base-transition: none !default;
$app-toolbar-base-height: null !default;
$app-toolbar-base-height-mobile: null !default;
$app-toolbar-base-z-index: 99 !default;
$app-toolbar-base-z-index-mobile: 99 !default;
$app-toolbar-base-bg-color: null !default;
$app-toolbar-base-bg-color-dark: null !default;
$app-toolbar-base-box-shadow: null !default;
$app-toolbar-base-box-shadow-dark: null !default;
$app-toolbar-base-border-top: null !default;
$app-toolbar-base-border-top-dark: null !default;
$app-toolbar-base-border-bottom: null !default;
$app-toolbar-base-border-bottom-dark: null !default;

// Toolbar minimize
$app-toolbar-minimize-height: 70px !default;
$app-toolbar-minimize-height-mobile: 70px !default;
$app-toolbar-minimize-z-index: 99 !default;
$app-toolbar-minimize-z-index-mobile: 99 !default;
$app-toolbar-minimize-backdrop-filter: null !default;
$app-toolbar-minimize-backdrop-filter-mobile: null !default;
$app-toolbar-minimize-bg-color: null !default;
$app-toolbar-minimize-bg-color-dark: null !default;
$app-toolbar-minimize-box-shadow: null !default;
$app-toolbar-minimize-box-shadow-dark: null !default;
$app-toolbar-minimize-border-top: null !default;
$app-toolbar-minimize-border-top-dark: null !default;
$app-toolbar-minimize-border-bottom: null !default;
$app-toolbar-minimize-border-bottom-dark: null !default;

// Toolbar sticky
$app-toolbar-sticky-height: 70px !default;
$app-toolbar-sticky-height-mobile: 70px !default;
$app-toolbar-sticky-z-index: 99 !default;
$app-toolbar-sticky-z-index-mobile: 99 !default;
$app-toolbar-sticky-backdrop-filter: null !default;
$app-toolbar-sticky-backdrop-filter-mobile: null !default;
$app-toolbar-sticky-bg-color: null !default;
$app-toolbar-sticky-bg-color-dark: null !default;
$app-toolbar-sticky-box-shadow: null !default;
$app-toolbar-sticky-box-shadow-dark: null !default;
$app-toolbar-sticky-border-top: null !default;
$app-toolbar-sticky-border-top-dark: null !default;
$app-toolbar-sticky-border-bottom: null !default;
$app-toolbar-sticky-border-bottom-dark: null !default;

// Hero
$app-hero-bg-color: null !default;
$app-hero-bg-color-dark: null !default;
$app-hero-box-shadow: null !default;
$app-hero-box-shadow-dark: null !default;
$app-hero-border-top: null !default;
$app-hero-border-top-dark: null !default;
$app-hero-border-bottom: null !default;
$app-hero-border-bottom-dark: null !default;

// Sidebar base
$app-sidebar-base-transition: width $app-general-transition-duration $app-general-transition-timing !default;
$app-sidebar-base-width: 300px !default;
$app-sidebar-base-width-mobile: 275px !default;
$app-sidebar-base-z-index: null !default;
$app-sidebar-base-z-index-mobile: 106 !default;
$app-sidebar-base-bg-color: null !default;
$app-sidebar-base-bg-color-dark: null !default;
$app-sidebar-base-box-shadow: null !default;
$app-sidebar-base-box-shadow-dark: null !default;
$app-sidebar-base-border-start: null !default;
$app-sidebar-base-border-start-dark: null !default;
$app-sidebar-base-border-end: null !default;
$app-sidebar-base-border-end-dark: null !default;
$app-sidebar-base-gap-start: 0px !default;
$app-sidebar-base-gap-end: 0px !default;
$app-sidebar-base-gap-top: 0px !default;
$app-sidebar-base-gap-bottom: 0px !default;
$app-sidebar-base-gap-start-mobile: 0px !default;
$app-sidebar-base-gap-end-mobile: 0px !default;
$app-sidebar-base-gap-top-mobile: 0px !default;
$app-sidebar-base-gap-bottom-mobile: 0px !default;

// Sidebar fixed
$app-sidebar-fixed-z-index: 105 !default;
$app-sidebar-fixed-left: 0 !default;
$app-sidebar-fixed-top: 0 !default;
$app-sidebar-fixed-bottom: 0 !default;

// Sidebar sticky
$app-sidebar-sticky-top: auto !default;
$app-sidebar-sticky-bottom: auto !default;
$app-sidebar-sticky-left: auto !default;
$app-sidebar-sticky-width: 300px !default;
$app-sidebar-sticky-z-index: 105 !default;
$app-sidebar-sticky-bg-color: null !default;
$app-sidebar-sticky-bg-color-dark: null !default;
$app-sidebar-sticky-box-shadow: null !default;
$app-sidebar-sticky-box-shadow-dark: null !default;
$app-sidebar-sticky-border-start: null !default;
$app-sidebar-sticky-border-start-dark: null !default;
$app-sidebar-sticky-border-end: null !default;
$app-sidebar-sticky-border-end-dark: null !default;
$app-sidebar-sticky-gap-start: 0px !default;
$app-sidebar-sticky-gap-end: 0px !default;
$app-sidebar-sticky-gap-top: 0px !default;
$app-sidebar-sticky-gap-bottom: 0px !default;
$app-sidebar-sticky-gap-start-mobile: 0px !default;
$app-sidebar-sticky-gap-end-mobile: 0px !default;
$app-sidebar-sticky-gap-top-mobile: 0px !default;
$app-sidebar-sticky-gap-bottom-mobile: 0px !default;

// Sidebar minimize
$app-sidebar-minimize-width: 75px !default;
$app-sidebar-minimize-width-mobile: 75px !default;
$app-sidebar-minimize-bg-color: null !default;
$app-sidebar-minimize-bg-color-dark: null !default;
$app-sidebar-minimize-box-shadow: null !default;
$app-sidebar-minimize-box-shadow-dark: null !default;
$app-sidebar-minimize-hover-box-shadow: null !default;
$app-sidebar-minimize-hover-box-shadow-dark: null !default;
$app-sidebar-minimize-border-start: null !default;
$app-sidebar-minimize-border-start-dark: null !default;
$app-sidebar-minimize-border-end: null !default;
$app-sidebar-minimize-border-end-dark: null !default;
$app-sidebar-minimize-gap-start: 0px !default;
$app-sidebar-minimize-gap-end: 0px !default;
$app-sidebar-minimize-gap-top: 0px !default;
$app-sidebar-minimize-gap-bottom: 0px !default;
$app-sidebar-minimize-gap-start-mobile: 0px !default;
$app-sidebar-minimize-gap-end-mobile: 0px !default;
$app-sidebar-minimize-gap-top-mobile: 0px !default;
$app-sidebar-minimize-gap-bottom-mobile: 0px !default;

// Sidebar primary
$app-sidebar-primary-base-transition: none !default;
$app-sidebar-primary-base-width: 100px !default;
$app-sidebar-primary-base-width-mobile: 100px !default;
$app-sidebar-primary-base-z-index: 1 !default;
$app-sidebar-primary-base-z-index-mobile: 1 !default;
$app-sidebar-primary-base-bg-color: null !default;
$app-sidebar-primary-base-bg-color-dark: null !default;
$app-sidebar-primary-base-box-shadow: null !default;
$app-sidebar-primary-base-box-shadow-dark: null !default;
$app-sidebar-primary-base-border-start: null !default;
$app-sidebar-primary-base-border-start-dark: null !default;
$app-sidebar-primary-base-border-end: null !default;
$app-sidebar-primary-base-border-end-dark: null !default;
$app-sidebar-primary-base-gap-start: 0px !default;
$app-sidebar-primary-base-gap-end: 0px !default;
$app-sidebar-primary-base-gap-top: 0px !default;
$app-sidebar-primary-base-gap-bottom: 0px !default;
$app-sidebar-primary-base-gap-start-mobile: 0px !default;
$app-sidebar-primary-base-gap-end-mobile: 0px !default;
$app-sidebar-primary-base-gap-top-mobile: 0px !default;
$app-sidebar-primary-base-gap-bottom-mobile: 0px !default;

$app-sidebar-primary-minimize-width: 75px !default;
$app-sidebar-primary-minimize-width-mobile: 75px !default;
$app-sidebar-primary-minimize-z-index: null !default;
$app-sidebar-primary-minimize-bg-color: null !default;
$app-sidebar-primary-minimize-bg-color-dark: null !default;
$app-sidebar-primary-minimize-box-shadow: null !default;
$app-sidebar-primary-minimize-box-shadow-dark: null !default;
$app-sidebar-primary-minimize-hover-box-shadow: null !default;
$app-sidebar-primary-minimize-hover-box-shadow-dark: null !default;
$app-sidebar-primary-minimize-border-start: null !default;
$app-sidebar-primary-minimize-border-start-dark: null !default;
$app-sidebar-primary-minimize-border-end: null !default;
$app-sidebar-primary-minimize-border-end-dark: null !default;
$app-sidebar-primary-minimize-gap-start: 0px !default;
$app-sidebar-primary-minimize-gap-end: 0px !default;
$app-sidebar-primary-minimize-gap-top: 0px !default;
$app-sidebar-primary-minimize-gap-bottom: 0px !default;
$app-sidebar-primary-minimize-gap-start-mobile: 0px !default;
$app-sidebar-primary-minimize-gap-end-mobile: 0px !default;
$app-sidebar-primary-minimize-gap-top-mobile: 0px !default;
$app-sidebar-primary-minimize-gap-bottom-mobile: 0px !default;

// Sidebar secondary
$app-sidebar-secondary-base-transition: width $app-general-transition-duration
    $app-general-transition-timing,
  margin $app-general-transition-duration $app-general-transition-timing !default;
$app-sidebar-secondary-base-z-index: null !default;
$app-sidebar-secondary-base-z-index-mobile: null !default;
$app-sidebar-secondary-base-bg-color: null !default;
$app-sidebar-secondary-base-bg-color-dark: null !default;
$app-sidebar-secondary-base-box-shadow: null !default;
$app-sidebar-secondary-base-box-shadow-dark: null !default;
$app-sidebar-secondary-base-border-start: null !default;
$app-sidebar-secondary-base-border-start-dark: null !default;
$app-sidebar-secondary-base-border-end: null !default;
$app-sidebar-secondary-base-border-end-dark: null !default;
$app-sidebar-secondary-base-gap-start: 0px !default;
$app-sidebar-secondary-base-gap-end: 0px !default;
$app-sidebar-secondary-base-gap-top: 0px !default;
$app-sidebar-secondary-base-gap-bottom: 0px !default;
$app-sidebar-secondary-base-gap-start-mobile: 0px !default;
$app-sidebar-secondary-base-gap-end-mobile: 0px !default;
$app-sidebar-secondary-base-gap-top-mobile: 0px !default;
$app-sidebar-secondary-base-gap-bottom-mobile: 0px !default;

$app-sidebar-secondary-minimize-width: 75px !default;
$app-sidebar-secondary-minimize-width-mobile: 75px !default;
$app-sidebar-secondary-minimize-z-index: null !default;
$app-sidebar-secondary-minimize-bg-color: null !default;
$app-sidebar-secondary-minimize-bg-color-dark: null !default;
$app-sidebar-secondary-minimize-box-shadow: null !default;
$app-sidebar-secondary-minimize-box-shadow-dark: null !default;
$app-sidebar-secondary-minimize-hover-box-shadow: null !default;
$app-sidebar-secondary-minimize-hover-box-shadow-dark: null !default;
$app-sidebar-secondary-minimize-border-start: null !default;
$app-sidebar-secondary-minimize-border-start-dark: null !default;
$app-sidebar-secondary-minimize-border-end: null !default;
$app-sidebar-secondary-minimize-border-end-dark: null !default;
$app-sidebar-secondary-minimize-gap-start: 0px !default;
$app-sidebar-secondary-minimize-gap-end: 0px !default;
$app-sidebar-secondary-minimize-gap-top: 0px !default;
$app-sidebar-secondary-minimize-gap-bottom: 0px !default;
$app-sidebar-secondary-minimize-gap-start-mobile: 0px !default;
$app-sidebar-secondary-minimize-gap-end-mobile: 0px !default;
$app-sidebar-secondary-minimize-gap-top-mobile: 0px !default;
$app-sidebar-secondary-minimize-gap-bottom-mobile: 0px !default;

// Sidebar panel base
$app-sidebar-panel-base-transition: none !default;
$app-sidebar-panel-base-width: 300px !default;
$app-sidebar-panel-base-width-mobile: 300px !default;
$app-sidebar-panel-base-z-index: null !default;
$app-sidebar-panel-base-z-index-mobile: null !default;
$app-sidebar-panel-base-bg-color: null !default;
$app-sidebar-panel-base-bg-color-dark: null !default;
$app-sidebar-panel-base-bg-color-mobile: null !default;
$app-sidebar-panel-base-bg-color-mobile-dark: null !default;
$app-sidebar-panel-base-box-shadow: null !default;
$app-sidebar-panel-base-box-shadow-dark: null !default;
$app-sidebar-panel-base-box-shadow-mobile: null !default;
$app-sidebar-panel-base-box-shadow-mobile-dark: null !default;
$app-sidebar-panel-base-border-start: null !default;
$app-sidebar-panel-base-border-start-dark: null !default;
$app-sidebar-panel-base-border-start-mobile: null !default;
$app-sidebar-panel-base-border-start-mobile-dark: null !default;
$app-sidebar-panel-base-border-end: null !default;
$app-sidebar-panel-base-border-end-dark: null !default;
$app-sidebar-panel-base-border-end-mobile: null !default;
$app-sidebar-panel-base-border-end-mobile-dark: null !default;
$app-sidebar-panel-base-gap-start: 0px !default;
$app-sidebar-panel-base-gap-end: 0px !default;
$app-sidebar-panel-base-gap-top: 0px !default;
$app-sidebar-panel-base-gap-bottom: 0px !default;
$app-sidebar-panel-base-gap-start-mobile: 0px !default;
$app-sidebar-panel-base-gap-end-mobile: 0px !default;
$app-sidebar-panel-base-gap-top-mobile: 0px !default;
$app-sidebar-panel-base-gap-bottom-mobile: 0px !default;

// Sidebar panel fixed
$app-sidebar-panel-fixed-z-index: 104 !default;
$app-sidebar-panel-fixed-z-index-mobile: 105 !default;

// Sidebar panel sticky
$app-sidebar-panel-sticky-top: auto !default;
$app-sidebar-panel-sticky-bottom: auto !default;
$app-sidebar-panel-sticky-width: 300px !default;
$app-sidebar-panel-sticky-z-index: 104 !default;
$app-sidebar-panel-sticky-bg-color: null !default;
$app-sidebar-panel-sticky-bg-color-dark: null !default;
$app-sidebar-panel-sticky-box-shadow: null !default;
$app-sidebar-panel-sticky-box-shadow-dark: null !default;
$app-sidebar-panel-sticky-border-start: null !default;
$app-sidebar-panel-sticky-border-start-dark: null !default;
$app-sidebar-panel-sticky-border-end: null !default;
$app-sidebar-panel-sticky-border-end-dark: null !default;
$app-sidebar-panel-sticky-gap-start: 0px !default;
$app-sidebar-panel-sticky-gap-end: 0px !default;
$app-sidebar-panel-sticky-gap-top: 0px !default;
$app-sidebar-panel-sticky-gap-bottom: 0px !default;
$app-sidebar-panel-sticky-gap-start-mobile: 0px !default;
$app-sidebar-panel-sticky-gap-end-mobile: 0px !default;
$app-sidebar-panel-sticky-gap-top-mobile: 0px !default;
$app-sidebar-panel-sticky-gap-bottom-mobile: 0px !default;

// Sidebar panel minimize
$app-sidebar-panel-minimize-width: 75px !default;
$app-sidebar-panel-minimize-width-mobile: 75px !default;
$app-sidebar-panel-minimize-bg-color: null !default;
$app-sidebar-panel-minimize-bg-color-dark: null !default;
$app-sidebar-panel-minimize-bg-color-mobile: null !default;
$app-sidebar-panel-minimize-bg-color-mobile-dark: null !default;
$app-sidebar-panel-minimize-box-shadow: null !default;
$app-sidebar-panel-minimize-box-shadow-dark: null !default;
$app-sidebar-panel-minimize-box-shadow-mobile: null !default;
$app-sidebar-panel-minimize-box-shadow-mobile-dark: null !default;
$app-sidebar-panel-minimize-hover-box-shadow: null !default;
$app-sidebar-panel-minimize-hover-box-shadow-dark: null !default;
$app-sidebar-panel-minimize-hover-box-shadow-mobile: null !default;
$app-sidebar-panel-minimize-hover-box-shadow-mobile-dark: null !default;
$app-sidebar-panel-minimize-border-start: null !default;
$app-sidebar-panel-minimize-border-start-dark: null !default;
$app-sidebar-panel-minimize-border-start-mobile: null !default;
$app-sidebar-panel-minimize-border-start-mobile-dark: null !default;
$app-sidebar-panel-minimize-border-end: null !default;
$app-sidebar-panel-minimize-border-end-dark: null !default;
$app-sidebar-panel-minimize-border-end-mobile: null !default;
$app-sidebar-panel-minimize-border-end-mobile-dark: null !default;
$app-sidebar-panel-minimize-gap-start: 0px !default;
$app-sidebar-panel-minimize-gap-end: 0px !default;
$app-sidebar-panel-minimize-gap-top: 0px !default;
$app-sidebar-panel-minimize-gap-bottom: 0px !default;
$app-sidebar-panel-minimize-gap-start-mobile: 0px !default;
$app-sidebar-panel-minimize-gap-end-mobile: 0px !default;
$app-sidebar-panel-minimize-gap-top-mobile: 0px !default;
$app-sidebar-panel-minimize-gap-bottom-mobile: 0px !default;

// Aside base
$app-aside-base-transition: none;
$app-aside-base-width: 300px !default;
$app-aside-base-width-mobile: 275px !default;
$app-aside-base-z-index: null !default;
$app-aside-base-z-index-mobile: 106 !default;
$app-aside-base-bg-color: null !default;
$app-aside-base-bg-color-dark: null !default;
$app-aside-base-bg-color-mobile: null !default;
$app-aside-base-bg-color-mobile-dark: null !default;
$app-aside-base-box-shadow: null !default;
$app-aside-base-box-shadow-dark: null !default;
$app-aside-base-box-shadow-mobile: null !default;
$app-aside-base-box-shadow-mobile-dark: null !default;
$app-aside-base-border-start: null !default;
$app-aside-base-border-start-dark: null !default;
$app-aside-base-border-start-mobile: null !default;
$app-aside-base-border-start-mobile-dark: null !default;
$app-aside-base-border-end: null !default;
$app-aside-base-border-end-dark: null !default;
$app-aside-base-border-end-mobile: null !default;
$app-aside-base-border-end-mobile-dark: null !default;
$app-aside-base-gap-start: 0px !default;
$app-aside-base-gap-end: 0px !default;
$app-aside-base-gap-top: 0px !default;
$app-aside-base-gap-bottom: 0px !default;
$app-aside-base-gap-start-mobile: 0px !default;
$app-aside-base-gap-end-mobile: 0px !default;
$app-aside-base-gap-top-mobile: 0px !default;
$app-aside-base-gap-bottom-mobile: 0px !default;

// Aside fixed
$app-aside-fixed-z-index: 105 !default;
$app-aside-fixed-right: 0 !default;
$app-aside-fixed-top: 0 !default;
$app-aside-fixed-bottom: 0 !default;

// Aside sticky
$app-aside-sticky-top: auto !default;
$app-aside-sticky-bottom: auto !default;
$app-aside-sticky-left: auto !default;
$app-aside-sticky-width: 300px !default;
$app-aside-sticky-z-index: 105 !default;
$app-aside-sticky-bg-color: null !default;
$app-aside-sticky-bg-color-dark: null !default;
$app-aside-sticky-box-shadow: null !default;
$app-aside-sticky-box-shadow-dark: null !default;
$app-aside-sticky-border-start: null !default;
$app-aside-sticky-border-start-dark: null !default;
$app-aside-sticky-border-end: null !default;
$app-aside-sticky-border-end-dark: null !default;
$app-aside-sticky-gap-start: 0px !default;
$app-aside-sticky-gap-end: 0px !default;
$app-aside-sticky-gap-top: 0px !default;
$app-aside-sticky-gap-bottom: 0px !default;
$app-aside-sticky-gap-start-mobile: 0px !default;
$app-aside-sticky-gap-end-mobile: 0px !default;
$app-aside-sticky-gap-top-mobile: 0px !default;
$app-aside-sticky-gap-bottom-mobile: 0px !default;

// Aside minimize
$app-aside-minimize-width: 75px !default;
$app-aside-minimize-width-mobile: 75px !default;
$app-aside-minimize-bg-color: null !default;
$app-aside-minimize-bg-color-dark: null !default;
$app-aside-minimize-bg-color-mobile: null !default;
$app-aside-minimize-bg-color-mobile-dark: null !default;
$app-aside-minimize-box-shadow: null !default;
$app-aside-minimize-box-shadow-dark: null !default;
$app-aside-minimize-box-shadow-mobile: null !default;
$app-aside-minimize-box-shadow-mobile-dark: null !default;
$app-aside-minimize-hover-box-shadow: null !default;
$app-aside-minimize-hover-box-shadow-dark: null !default;
$app-aside-minimize-border-start: null !default;
$app-aside-minimize-border-start-dark: null !default;
$app-aside-minimize-border-end: null !default;
$app-aside-minimize-border-end-dark: null !default;
$app-aside-minimize-gap-start: 0px !default;
$app-aside-minimize-gap-end: 0px !default;
$app-aside-minimize-gap-top: 0px !default;
$app-aside-minimize-gap-bottom: 0px !default;
$app-aside-minimize-gap-start-mobile: 0px !default;
$app-aside-minimize-gap-end-mobile: 0px !default;
$app-aside-minimize-gap-top-mobile: 0px !default;
$app-aside-minimize-gap-bottom-mobile: 0px !default;

// Footer
$app-footer-transition: left $app-general-transition-duration $app-general-transition-timing,
  right $app-general-transition-duration $app-general-transition-timing;
$app-footer-height: auto !default;
$app-footer-height-mobile: auto !default;
$app-footer-z-index: null !default;
$app-footer-z-index-mobile: null !default;
$app-footer-bg-color: null !default;
$app-footer-bg-color-dark: null !default;
$app-footer-box-shadow: null !default;
$app-footer-box-shadow-dark: null !default;
$app-footer-border-top: null !default;
$app-footer-border-top-dark: null !default;

// Footer fixed
$app-footer-fixed-z-index: 100 !default;
$app-footer-fixed-z-index-mobile: 100 !default;

// Layout Builder
$app-layout-builder-toggle-z-index: 105 !default;
$app-layout-builder-toggle-bottom: 40px !default;
$app-layout-builder-toggle-bottom-mobile: 15px !default;
$app-layout-builder-toggle-end: 40px !default;
$app-layout-builder-toggle-end-mobile: 15px !default;
