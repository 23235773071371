.divide-y-dash > *:not(:last-child) {
  border-bottom: 1px dashed #e1e3ea;
}
.timeline {
  .timeline-container:not(:last-child) {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      border-right: 1px dashed #e1e3ea;
      top: 48px;
      bottom: 0;
      left: 24px;
      z-index: 0;
    }
  }
}
